import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { DocTypeType } from '../../data/docTypes';
import { getNodePathForDocPage } from '../../utils';
import { Constrain } from '../Containers';
import NewSupport from '../NewSupport';
import { Article, PageTitle } from '../Typography';
import Card from '../Card';
import DocsSidebar from '../DocsSidebar';

export type DocRootProps = {
	data: NonNullable<GatsbyTypes.DocRootDocsQuery['allMdx']>;
	docType: DocTypeType;
};
export default function DocRoot(props: DocRootProps) {
	const { data, docType } = props;
	const { file } = useStaticQuery<GatsbyTypes.GeneralDocImageQuery>(graphql`
		query GeneralDocImage {
			file(absolutePath: { glob: "**/src/images/general-documentation.png" }) {
				childImageSharp {
					gatsbyImageData(
						layout: CONSTRAINED
						width: 800
						tracedSVGOptions: { color: "#0967D2" }
						placeholder: TRACED_SVG
					)
				}
			}
		}
	`);
	return (
		<Constrain>
			<DocsSidebar.Grid>
				<DocsSidebar />
				<DocsSidebar.Content>
					<PageTitle>{docType.title}</PageTitle>
					<Article className="has-gutter">
						{docType.longDescription ?? <p>{docType.description}</p>}
					</Article>
					<Card.Grid>
						{data.edges.map(({ node }, index) => (
							<Card
								key={node.fields?.slug}
								to={getNodePathForDocPage(node)}
								layout="max-2"
								image={
									<>
										<GatsbyImage
											image={
												node.frontmatter?.featuredImage?.childImageSharp
													?.gatsbyImageData ??
												file!.childImageSharp!.gatsbyImageData
											}
											alt={node.frontmatter?.title ?? ''}
										/>
										<Card.Tag>
											<span>{index + 1}</span>
										</Card.Tag>
									</>
								}
							>
								<Card.Title>{node.frontmatter?.title}</Card.Title>
								<Card.Description>
									{node.frontmatter?.excerpt ?? node.excerpt}
								</Card.Description>
								<Card.FakeButton>Continue Reading…</Card.FakeButton>
							</Card>
						))}
					</Card.Grid>
				</DocsSidebar.Content>
			</DocsSidebar.Grid>
			<NewSupport type="docRoot" />
		</Constrain>
	);
}
