import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { docTypes } from '../data/docTypes';
import Seo from '../components/Seo';
import NotFound from '../components/NotFound';
import DocRoot from '../components/DocRoot';
import DocDataProvider from '../components/DocDataProvider';

type PageContextType = {
	docType: string;
};
export default function DocTemplate(
	props: PageProps<GatsbyTypes.DocRootDocsQuery, PageContextType>
) {
	const {
		data: { allMdx },
		pageContext: { docType },
	} = props;
	const docDefinition = docTypes.find(dt => dt.docType === docType);
	if (!docDefinition || !allMdx) {
		return <NotFound />;
	}

	return (
		<DocDataProvider>
			<Seo
				description={docDefinition.description}
				title={docDefinition.title}
			/>
			<DocRoot docType={docDefinition} data={allMdx} />
		</DocDataProvider>
	);
}

export const query = graphql`
	query DocRootDocs($docType: String!) {
		allMdx(
			sort: { order: ASC, fields: frontmatter___order }
			filter: { fields: { docType: { eq: $docType } } }
		) {
			edges {
				node {
					frontmatter {
						title
						featuredImage {
							childImageSharp {
								gatsbyImageData(
									layout: CONSTRAINED
									width: 800
									aspectRatio: 1.9047619
									tracedSVGOptions: { color: "#0967D2" }
									placeholder: TRACED_SVG
								)
							}
						}
						excerpt
						slug
					}
					fields {
						slug
						docType
					}
					excerpt(pruneLength: 200, truncate: true)
				}
			}
		}
	}
`;
